<template>
  <v-card>
    <v-card-title class="justify-center">
      Tambah User
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          class="py-0"
        >
          <div>
            <v-autocomplete
              v-model="user.role"
              label="Role"
              item-value="role"
              :items="roles"
              dense
              outlined
            >
            </v-autocomplete>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="user.role == 'student'">
        <v-col>
          <div>
            <v-text-field
              v-model="user.nis"
              label="NIS"
              dense
              outlined
              type="number"
            ></v-text-field>
          </div>
        </v-col>
        <v-col>
          <div>
            <v-text-field
              v-model="user.nisn"
              label="NISN"
              dense
              outlined
              type="number"
            ></v-text-field>
          </div>
        </v-col>
        <v-col>
          <div>
            <v-text-field
              v-model="user.nik_ktp"
              label="NIK"
              dense
              outlined
              type="number"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <div>
          <v-text-field
            v-if="user.role == 'school'"
            v-model="user.npsn"
            label="NPSN"
            dense
            type="number"
            outlined
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="user.name"
            label="Nama"
            dense
            outlined
          ></v-text-field>
        </div>
      </v-row>
      <v-row>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="user.username"
              label="Username"
              dense
              outlined
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="user.email"
              label="Email"
              dense
              type="email"
              outlined
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="user.role == 'student'">
        <v-col>
          <v-autocomplete
            v-model="user.gender"
            label="Jenis Kelamin"
            :items="genders"
            dense
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col>
          <div>
            <v-autocomplete
              v-model="user.religion"
              label="Agama"
              :items="religions"
              dense
              outlined
            ></v-autocomplete>
          </div>
        </v-col>
        <v-col>
          <div>
            <v-text-field
              v-model="user.ethnic_group"
              label="Suku"
              dense
              outlined
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="user.role == 'student'">
        <v-col>
          <div>
            <v-text-field
              v-model="user.place_of_birth"
              label="Tempat Lahir"
              dense
              outlined
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              v-model="user.weight"
              label="Berat Badan (Kg)"
              dense
              outlined
            ></v-text-field>
          </div>
          <div>
            <v-autocomplete
              v-model="user.blood_type"
              :items="blood_types"
              label="Golongan Darah"
              dense
              outlined
            ></v-autocomplete>
          </div>
        </v-col>
        <v-col>
          <div>
            <v-text-field
              v-model="user.date_of_birth"
              label="Tanggal Lahir"
              dense
              type="date"
              outlined
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              v-model="user.height"
              label="Tinggi Badan (Cm)"
              dense
              outlined
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              v-model="user.hobby"
              label="Hobi"
              dense
              outlined
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <div>
          <v-textarea
            v-if="user.role == 'student'"
            v-model="user.disease_history"
            label="Riwayat Penyakit"
            outlined
            dense
          >
          </v-textarea>
        </div>
      </v-row>
      <v-row v-if="user.role == 'student'">
        <v-col>
          <div>
            <v-text-field
              v-model="user.transport"
              label="Mode Transportasi"
              outlined
              dense
            >
            </v-text-field>
          </div>
          <div>
            <v-text-field
              v-model="user.home_phone"
              label="Telepon Rumah"
              type="number"
              outlined
              dense
            >
            </v-text-field>
          </div>
        </v-col>
        <v-col>
          <div>
            <v-text-field
              v-if="user.role == 'student'"
              v-model="user.home_distance"
              label="Jarak dari rumah ke sekolah (KM)"
              outlined
              dense
            >
            </v-text-field>
          </div>
          <div>
            <v-text-field
              v-if="user.role == 'student'"
              v-model="user.phone"
              label="Nomor Telepon"
              type="number"
              outlined
              dense
            >
            </v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <div>
          <v-text-field
            v-if="user.role == 'company'"
            v-model="user.phone"
            label="Nomor Telepon"
            dense
            type="number"
            outlined
          ></v-text-field>
        </div>
        <div>
          <v-autocomplete
            v-if="user.role == 'school'"
            v-model="user.jenjang"
            label="Jenjang"
            item-value="level"
            :items="levels"
            dense
            outlined
          >
          </v-autocomplete>
        </div>

        <div>
          <v-autocomplete
            v-if="user.role == 'school'"
            v-model="user.tahun_ajaran"
            label="Tahun Ajaran"
            item-value="uuid"
            :items="years"
            dense
            outlined
          >
          </v-autocomplete>
        </div>
        <div>
          <v-autocomplete
            v-if="user.role == 'school'"
            v-model="user.semester"
            label="Semester"
            item-value="uuid"
            :items="semester"
            dense
            outlined
          >
          </v-autocomplete>
        </div>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="isLoadingButton"
        :loading="isLoadingButton"
        color="primary"
        large
        @click="handlerSubmit"
      >
        Tambah
      </v-btn>
      <router-link
        to="/user"
        class="v-btn v-btn--outlined theme--light v-size--large  primary--text"
      >
        Batal
      </router-link>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mdiAccount } from '@mdi/js'

export default {
  name: 'UserAdd',
  components: {
    // FileInput,
  },
  data() {
    return {
      user: {
        role: '',
        name: '',
        username: '',
        email: '',
        npsn: '',
        jenjang: '',
        tahun_ajaran: '',
        logo: null,
        nis: '',
        nisn: '',
        nama_lengkap: '',
        nama_panggilan: '',
        nik_ktp: '',
        gender: '',
        religion: '',
        ethnic_group: '',
        date_of_birth: '',
        place_of_birth: '',
        weight: '',
        height: '',
        blood_type: '',
        hobby: '',
        disease_history: '',
        transport: '',
        home_distance: '',
        home_phone: '',
        phone: '',
      },
      icons: {
        mdiAccount,
      },
      permissions: [
        {
          action: 'Read',
        },
        {
          action: 'Write',
        },
        {
          action: 'Update',
        },
        {
          action: 'Delete',
        },
      ],
      roles: ['student', 'school', 'company'],
      levels: ['SD', 'SMP', 'SMA', 'SMK'],
      semester: ['Genap', 'Ganjil'],
      genders: ['Laki-Laki', 'Perempuan'],
      religions: ['Islam', 'Kristen', 'Hindu', 'Budha', 'Konghucu'],
      years: ['2020/2021', '2021/2022', '2022/2023'],
      blood_types: ['A', 'AB', 'B', 'O'],
      isLoadingButton: false,
    }
  },

  methods: {
    resetForm() {
      this.user.role = ''
      this.user.name = ''
      this.user.username = ''
      this.user.email = ''
      this.user.phone = ''
      this.user.npsn = ''
      this.user.jenjang = ''
      this.user.tahun_ajaran = ''
      this.user.nis = ''
      this.user.nisn = ''
      this.user.nik_ktp = ''
      this.user.gender = ''
      this.user.religion = ''
      this.user.ethnic_group = ''
      this.user.date_of_birth = ''
      this.user.place_of_birth = ''
      this.user.weight = ''
      this.user.height = ''
      this.user.blood_type = ''
      this.user.hobby = ''
      this.user.disease_history = ''
      this.user.transport = ''
      this.user.home_distance = ''
      this.user.home_phone = ''
      this.user.phone = ''
    },
    async addSchool() {
      this.isLoadingButton = true
      await this.$services.UserServices.addSchool(this.user).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
    },
    async addStudent() {
      this.isLoadingButton = true
      await this.$services.UserServices.addStudent(this.user).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
    },
    handlerSubmit() {
      if (this.user.role === 'school') {
        this.addSchool()
      } else if (this.user.role === 'student') {
        this.addStudent()
      }
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
